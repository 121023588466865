import { Injectable, inject } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UtilsService } from '@core/shared/utils/utils.service';
import { UserStoreService } from '@core/services/store/user-store.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private userStorageService = inject(UserStoreService);
    private router = inject(Router);
    private utilsService = inject(UtilsService);

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    console.log('*** Error on Http ***');
                    switch (err.status) {
                        case 500:
                            console.log('Catching error on interceptor');
                            this.handle500Error(err);
                            break;
                        case 401:
                            console.log('Catching error on interceptor');
                            this.handle401Error(err);
                            break;
                        case 403:
                            console.log('Catching error on interceptor');
                            this.handle403Error(err);
                            break;
                        case 404:
                            console.log('Catching error on interceptor');
                            this.handle404Error(err);
                            break;
                        case 422:
                            console.log('Catching error on interceptor');
                            this.handle422Error(err);
                            break;
                        case 0:
                            console.log('Catching error on interceptor');
                            this.handle0Error(err);
                            break;
                        default:
                            this.handle0Error(err);
                            break;
                    }
                    return throwError(() => err);
                } else {
                    const error = err.error.message || err.statusText;
                    return throwError(() => error);
                }
            })
        );
    }

    private async handle401Error(err: HttpErrorResponse) {
        // Potentially check the exact error reason for the 400
        // then log out the user automatically

        const error =
            err.error.message || err.statusText || 'Not Controlled Error';

        this.utilsService.showToast({
            key: 'global-toast',
            severity: 'error',
            detail: `${error} status: ${err.status} `,
        });

        this.userStorageService.logout({onlyLocalLogout: true});
        this.router.navigate(['/auth/login'], { replaceUrl: true });
        // window.location.reload();
    }

    // We are not just authorized, we couldn't refresh token
    // or something else along the caching went wrong!
    private async handle403Error(err: HttpErrorResponse) {
        // Potentially check the exact error reason for the 400
        // then log out the user automatically

        this.userStorageService.logout();
        this.router.navigate(['/login'], { replaceUrl: true });
    }

    private async handle404Error(err: HttpErrorResponse) {
        const error =
            err.error.message || err.statusText || 'Not Controlled Error';
        this.utilsService.showToast({
            key: 'global-toast',
            severity: 'error',
            detail: `${error} status: ${err.status} `,
        });
    }

    private async handle0Error(err: HttpErrorResponse) {
        const error =
            err.error.message || err.statusText || 'Not Controlled Error';
        this.utilsService.showToast({
            key: 'global-toast',
            severity: 'error',
            detail: `${error} status: ${err.status} `,
        });
    }

    private async handle500Error(err: HttpErrorResponse) {
        const error =
            err.error.message || err.statusText || 'Not Controlled Error';
        this.utilsService.showToast({
            key: 'global-toast',
            severity: 'error',
            detail: `${error} status: ${err.status} `,
        });
    }

    private async handle422Error(err: HttpErrorResponse) {
        const error =
            err.error.message || err.statusText || 'Not Controlled Error';
        this.utilsService.showToast({
            key: 'global-toast',
            severity: 'error',
            detail: `${error} status: ${err.status} `,
        });
    }
}
