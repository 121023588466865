import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { Roles } from '@core/_enums/roles.enum';
import { UserStoreService } from '@core/services/store/user-store.service';

export const rolesGuard: CanActivateFn = (route, state) => {
    console.log('🚀 ~ rolesGuard ~ 🚀');

    const userOnSession = inject(UserStoreService).userSession;
    const router = inject(Router);
    const allowedRoles = route.data['roles'] as Array<Roles>;
    console.log('🚀 ~ allowedRoles:', allowedRoles);
    console.log('🚀 ~ userOnSession.roles:', userOnSession?.roles);

    //checking array roles inside array routes roles
    if (
        userOnSession &&
        userOnSession.roles.some((role) => allowedRoles.includes(role))
    ) {
        //found some role access for the actual Route
        // console.log('#found-some Access Role');

        return true;
    } else {
        // console.log('notFound Access Role');

        router.navigate(['/access-denied'], { replaceUrl: true });
        return false;
    }
};
