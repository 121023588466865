import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { UserStoreService } from '@core/services/store/user-store.service';

export const userAuthGuard: CanActivateFn = (route, state) => {
    console.log('🚀 ~ userAuthGuard ~ 🚀');

    const userStoreService = inject(UserStoreService);
    const router = inject(Router);

    const currentUser = userStoreService.userSession;
    console.log('🚀 ~ userAuthGuard - currentUser:', currentUser);

    if (currentUser) {
        return true;
    }

    router.navigate(['/auth/login'], {
        replaceUrl: true,
        queryParams: { returnUrl: state.url },
    });
    return false;
};
