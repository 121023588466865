export enum OAuthProviders {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
}

export enum StatusOAuthSync {
    SYNCED = 'synced',
    PROBLEM_EXIST = 'problem-exist',
    NOT_SYNC = 'not-sync',
}

export enum StatusOAuthSyncCallback {
    COMPLETE = 'complete',
    PARTIAL = 'partial',
    ERROR = 'error',
}

export enum KeyOAuthProvider {
    GOOGLE = 'google-calendar',
    MICROSOFT = 'outlook-calendar',
}
