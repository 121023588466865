import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TypesMenuIcon } from '@core/_enums/types-sidemenu.enum';

@Component({
    selector: 'app-icon-sidemenu',
    standalone: true,
    imports: [CommonModule],
    template: `
        <ng-container>
            <i
                *ngIf="item.id === TypesMenuIcon.Default"
                [ngClass]="item.icon"
                class="layout-menuitem-icon"
            ></i>
            <i
                *ngIf="item.id === TypesMenuIcon.Custom"
                class="layout-menuitem-icon mr-3 lg:mr-0"
            >
                <img alt="icon-sidemenu" [src]="item.icon" />
            </i>
        </ng-container>
    `,
    styleUrls: ['./icon-sidemenu.component.css'],
})
export class IconSidemenuComponent {
    @Input({ required: true }) item!: { icon: string; id: TypesMenuIcon };
    readonly TypesMenuIcon = TypesMenuIcon;
}
