import { Injectable } from '@angular/core';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { forkJoin, lastValueFrom } from 'rxjs';
import { environment } from '@environment/environment';
import { UserClaimant } from '@core/models/case.model';
import { UserData } from '@core/models/user.model';

declare const CometChatWidget: any;

const $$_k_d = '4c49986cc3c5af917148841426feebbb0612b554';
const $$_k_p = '365501da1e0aed29be95791a910762ab5e7c34a8';

const WIDGET_DEV = {
    ACTIVE_WIDGET_ID: 'e6828885-7227-4138-bf55-0baa0ef7b96d',
    DISABLED_WIDGET_ID: undefined,
    APP_COMETCHAT_ID: '255046ddca442bd9',
    REGION_COMETCHAT: 'us',
};

const WIDGET_PROD = {
    ACTIVE_WIDGET_ID: '63fcd9b8-670b-4657-8643-13ebf1230c32',
    DISABLED_WIDGET_ID: '35333e0a-1eb9-4911-9f69-c7544e28547f',
    APP_COMETCHAT_ID: '2544029cc34cb9d4',
    REGION_COMETCHAT: 'us',
};

@Injectable({
    providedIn: 'root',
})
export class ChatSettingsService {
    async init() {
        try {
            const result = await lastValueFrom(
                forkJoin({
                    initCoreCometChat: CometChat.init(
                        environment.production
                            ? WIDGET_PROD.APP_COMETCHAT_ID
                            : WIDGET_DEV.APP_COMETCHAT_ID,
                        new CometChat.AppSettingsBuilder()
                            .subscribePresenceForAllUsers()
                            .setRegion(
                                environment.production
                                    ? WIDGET_PROD.REGION_COMETCHAT
                                    : WIDGET_DEV.REGION_COMETCHAT
                            )
                            .build()
                    ),
                    initCoreWidget: CometChatWidget.init({
                        appID: environment.production
                            ? WIDGET_PROD.APP_COMETCHAT_ID
                            : WIDGET_DEV.APP_COMETCHAT_ID,
                        appRegion: environment.production
                            ? WIDGET_PROD.REGION_COMETCHAT
                            : WIDGET_DEV.REGION_COMETCHAT,
                        authKey: environment.production ? $$_k_p : $$_k_d,
                    }),
                })
            );

            console.log(
                '🚀 ~ ChatSettingsService ~ initJOIN ~ result:',
                result
            );

            console.log('🚀 ~ Initialization completed successfully');

            CometChatWidget.localize(`en-US`);
        } catch (error) {
            console.log('🚀 ~ ChatSettingsService ~ initJOIN ~ error');
            console.log('Initialization failed with error:', error);
        }
    }

    async loginSDK(userID: number) {
        try {
            await CometChat.login(
                `${userID}`,
                environment.production ? $$_k_p : $$_k_d
            );

            console.log('🚀 ~ loginSDK User completed');
        } catch (error) {
            console.log('🚀 ~ ChatSettingsService ~ loginSDK ~ error:', error);
        }
    }

    async loginWidget(userID: number) {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });

        CometChatWidget.login({
            uid: `${userID}`,
        }).then(
            (isLoggedIn: any) => {
                console.log('🚀 ~ loginWidget User ~ isLoggedIn:', isLoggedIn);
                console.log('🚀 ~ loginWidget User completed');
                CometChatWidget.localize(`en-US`);
                resolveFunction(true);
            },
            (error: any) => {
                console.log(
                    '🚀 ~ ChatSettingsService ~ loginWidget ~ error:',
                    error
                );
                resolveFunction(false);
            }
        );

        return promise;
    }

    async createOrUpdateUser(userSession: UserData) {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });
        /**
         * Create user once initialization is successful
         */

        const user = new CometChatWidget.CometChat.User(`${userSession.id}`);
        user.setName(`${userSession.name} ${userSession.last_name}`);

        if (userSession.file)
            user.setAvatar(`${userSession.file.absolute_url}`);

        CometChatWidget.createOrUpdateUser(user).then(
            (response: string) => {
                console.log(
                    '🚀 ~ ChatSettingsService ~ awaitCometChatWidget.createOrUpdateUser ~ response:',
                    response
                );

                console.log('🚀 ~ User Created/Updated Successfully');
                resolveFunction(true);
            },
            (error: any) => {
                console.log(
                    '🚀 ~ ChatSettingsService ~ createOrUpdateUser ~ error:',
                    error
                );
                resolveFunction(false);
            }
        );

        return promise;
    }

    async subProcessCreateUserAndGroupAsync(
        disputeNumber: string | number,
        userIDCreateDispute: number,
        userSession: UserData
    ) {
        try {
            await this.init();

            //  Handle FallBack if User its not created
            await this.createOrUpdateUser(userSession);

            await this.loginWidget(userSession.id);

            // Handle FallBack if Group Chat its no created, Create now with Claimant Data
            await this.createOrUpdateGroupChat(disputeNumber, userIDCreateDispute);

            await this.logoutWidget();
        } catch (error) {
            console.log('🚀 ~ ChatSettingsService ~ error:', error);
        }
    }

    /**
     * Used by CLAIMANT only
     * @param disputeNumber
     */
    createOrUpdateGroupChat(
        disputeNumber: string | number,
        userIDCreateDispute: number
    ) {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });

        const GroupUID = this.getCorrectUIDByDispute(disputeNumber);
        const pwrUID = this.getPwrUIDByDispute(disputeNumber, userIDCreateDispute);
        console.log(
            '🚀 ~ ChatSettingsService ~ createOrUpdateGroupChat GroupUID:',
            GroupUID
        );

        // UID , NAME, TYPE
        const group = new CometChatWidget.CometChat.Group(
            GroupUID,
            GroupUID,
            CometChat.GroupType.Password,
            pwrUID
        );

        CometChatWidget.createOrUpdateGroup(group).then(
            (groupCreated: any) => {
                console.log('🚀 ~ createOrUpdateGroupChat: ', groupCreated);
                console.log('🚀 ~ Group Created Successfully');
                resolveFunction(true);
            },
            (error: any) => {
                console.log(
                    '🚀 ~ ChatSettingsService ~ createOrUpdateGroupChat ~ error:',
                    error
                );
                resolveFunction(false);
            }
        );

        return promise;
    }

    /**
     * For Respondet , Arbitrator , Legal REP Used Only
     * - Claimant its allways the Admin
     * @param disputeNumber
     * @param userIDCreatedDispute
     */
    async addMemberToGroup(
        disputeNumber: string | number,
        userIDCreatedDispute: number
    ) {
        const GroupUID = this.getCorrectUIDByDispute(disputeNumber);
        const pwrUID = this.getPwrUIDByDispute(
            disputeNumber,
            userIDCreatedDispute
        );
        try {
            await CometChat.joinGroup(
                GroupUID,
                CometChat.GroupType.Password,
                pwrUID
            );
            console.log('JOINED GROUP');
        } catch (error) {
            console.log(
                '🚀 ~ ChatSettingsService ~ addMemberToGroup ~ error:',
                error
            );
        }
    }

    async lauchChatWidget(
        disputeNumber: string | number,
        loadActiveOrDisabled: 'active' | 'disabled' = 'active'
    ) {
        await CometChatWidget.localize(`en-US`);
        CometChatWidget.launch({
            widgetID:
                loadActiveOrDisabled === 'active'
                    ? environment.production
                        ? WIDGET_PROD.ACTIVE_WIDGET_ID
                        : WIDGET_DEV.ACTIVE_WIDGET_ID
                    : environment.production
                    ? WIDGET_PROD.DISABLED_WIDGET_ID
                    : WIDGET_DEV.DISABLED_WIDGET_ID,
            target: '#chatWidget',
            roundedCorners: 'true',
            height: '100%',
            width: '100%',
            defaultID: this.getCorrectUIDByDispute(disputeNumber), //default UID (user) or GUID (group) to show,
            defaultType: 'group', //user or group
        }).then(() => {
            CometChatWidget.localize(`en-US`);
            CometChatWidget.openOrCloseChat(false); //true or false
        });
    }

    async logout() {
        try {
            await CometChatWidget.logout();

            console.log('🚀 ~ LogoutWidget User completed');
        } catch (error) {
            console.log(
                '🚀 ~ ChatSettingsService ~ LogoutWidget ~ error:',
                error
            );
        }

        try {
            await CometChat.logout();

            console.log('🚀 ~ LogoutSDK User completed');
        } catch (error) {
            console.log('🚀 ~ ChatSettingsService ~ LogoutSDK ~ error:', error);
        }
    }

    async logoutWidget() {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });
        CometChatWidget.logout().then(
            () => {
                console.log('🚀 ~ logoutWidget User completed');
                resolveFunction(true);
            },
            (error: any) => {
                console.log(
                    '🚀 ~ ChatSettingsService ~ logoutWidget ~ error:',
                    error
                );
                resolveFunction(false);
            }
        );

        return promise;
    }

    async logoutSDK() {
        try {
            await CometChat.logout();
            console.log('🚀 ~ logoutSDK User completed');
        } catch (error) {
            console.log('🚀 ~ ChatSettingsService ~ logoutSDK ~ error:', error);
        }
    }

    async checkUserLoggedInSDK() {
        try {
            const isUserLoggedIn = await CometChat.getLoggedinUser();
            console.log(
                '🚀 ~ ChatSettingsService ~ checkUserLoggedIn ~ isUserLoggedIn:',
                isUserLoggedIn
            );
            return isUserLoggedIn;
        } catch (error) {
            console.log(
                '🚀 ~ ChatSettingsService ~ checkUserLoggedIn ~ error:',
                error
            );
            return null;
        }
    }

    private getCorrectUIDByDispute(disputeNumber: string | number) {
        return typeof disputeNumber === 'string'
            ? disputeNumber.replaceAll('/', '_')
            : `Arb_${disputeNumber}_DRai`;
    }

    private getPwrUIDByDispute(
        disputeNumber: string | number,
        userIDCreateDispute: number
    ) {
        return typeof disputeNumber === 'string'
            ? `${disputeNumber.replaceAll('/', '#*')}_${userIDCreateDispute}`
            : `Arb.${disputeNumber}/DRai_${userIDCreateDispute}`;
    }

    private handlingLocalNotification() {
        // HANDLING NOTIFICATION LOCALLY ONLINE
        /*  CometChat.addMessageListener(
               `${userID}`,
                new CometChat.MessageListener({
                    onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
                        console.log("Text message received successfully", textMessage);
                    },
                    onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
                        console.log("Media message received successfully", mediaMessage);
                    },
                    onCustomMessageReceived: (customMessage: CometChat.CustomMessage) => {
                        console.log("Custom message received successfully", customMessage);
                    },
                    onMessagesDelivered: (messageReceipt: CometChat.MessageReceipt) => {
                        console.log("Message Delivered", messageReceipt);
                    },
                    onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
                        console.log("Message Read", messageReceipt);
                    },
                    onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
                        console.log("Typing Started", typingIndicator);
                    },
                    onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {
                        console.log("Typing Ended", typingIndicator);
                    },
                    onMessagesDeleted: (message: CometChat.BaseMessage) => {
                        console.log("Message Delted", message);
                    },
                    onMessagesEdited: (message: CometChat.BaseMessage) => {
                        console.log("Message Edited", message);
                    }
                })
            ); */
    }
}
