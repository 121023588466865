<div class="layout-container" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper">
        <div class="layout-content">
            <div class="layout-content-inner">
                <app-breadcrumb></app-breadcrumb>
                <router-outlet></router-outlet>
                <app-footer *ngIf="layoutService.isMobile()"></app-footer>
            </div>
        </div>
    </div>
    <app-footer *ngIf="layoutService.isDesktop()"></app-footer>
    <!-- <app-config></app-config> -->
</div>
