import { MenuItem } from 'primeng/api';
import { inject } from '@angular/core';
import { TypesMenuIcon } from '@core/_enums/types-sidemenu.enum';
import { UserStoreService } from '@core/services/store/user-store.service';

export class SuperAdminRoutes {
    private userStoreService = inject(UserStoreService);

    Routes: MenuItem[] = [
        {
            id: TypesMenuIcon.Custom,
            label: 'Disputes',
            icon: 'assets/icons/sidemenu/my-cases.svg',
            routerLink: '/my-disputes',
        },
        {
            separator: true,
            visible: false,
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'Settings',
            icon: 'assets/icons/sidemenu/settings.svg',
            routerLink: '/settings',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'Logout',
            icon: 'assets/icons/sidemenu/logout.svg',
            command: () => {
                console.log(
                    '🚀 ~ file: super-admin.routes.ts:39 ~ command Logout:'
                );
                this.userStoreService.logout();
            },
        },
    ];

    RoutesSettings: MenuItem[] = [
        {
            id: TypesMenuIcon.Custom,
            label: 'SETTINGS',
            routerLink: 'user-info',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'SEND REGISTRATION EMAIL',
            routerLink: 'send-registration-process',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'SECURITY',
            routerLink: 'account-security',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'NOTIFICATIONS',
            disabled: true,
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'ACCOUNT PROFILE',
            routerLink: 'account-profile',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'ACCOUNT TIMEZONE',
            routerLink: 'account-timezone',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'USERS',
            disabled: true,
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'CONFIGURATIONS',
            routerLink: 'configurations',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'SALES REPRESENTATIVE',
            routerLink: 'sales-representative',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'ARBITRATORS',
            routerLink: 'arbitrators',
        },
    ];
}
