export enum Roles {
    SuperAdmin = 'SUPER-ADMIN',
    Admin = 'ADMIN',
    Claimant = 'CLAIMANT',
    Respondent = 'RESPONDENT',
    SalesRepresentative = 'SALES-REPRESENTATIVE',
    BranchManager = 'BRANCH-MANAGER',
    Client = 'CLIENT',
    Arbitrator = 'ARBITRATOR',
    LegalRepresentative = 'REPRESENTATIVE',
    Undefined = 'UNDEFINED'
}
