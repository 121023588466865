<p-toast key="global-toast" [baseZIndex]="10001"></p-toast>
<p-toast
    key="global-toast-tr"
    [baseZIndex]="10001"
    position="top-right"
></p-toast>
<p-toast
    key="global-toast-bc"
    [baseZIndex]="10001"
    position="bottom-center"
></p-toast>
<p-confirmPopup key="global-confirm-popup"></p-confirmPopup>
<p-confirmDialog
    key="global-confirm-dialog-center"
    styleClass="w-11 md:w-6"
></p-confirmDialog>
<p-confirmDialog
    key="global-confirm-dialog-top"
    position="top"
    styleClass="w-11 md:w-6"
></p-confirmDialog>
<p-confirmDialog
    key="global-confirm-dialog-bottom"
    position="bottom"
    styleClass="w-11 md:w-6"
></p-confirmDialog>
<p-confirmDialog
    key="global-confirm-dialog-arbitrator"
    position="center"
    styleClass="w-11 md:w-4 custom-modal"
></p-confirmDialog>
<router-outlet></router-outlet>
