export enum SocketPresenceUser {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    RECONNECT = 'reconnect',
    LISTEN_USERS_ONLINE = 'users_online',
    LISTEN_DISPUTE_BY_ID = 'caseDispute_',
    LISTEN_COUNTERPART_PRESENCE = 'userPresence_',
    EMIT_JOINING_DISPUTE = 'caseDispute',
    EMIT_PRESENCE_USER = 'userPresence',
    EMIT_DISCONNECTION_DIPUSTE = 'disconnectingCaseDispute',
    EMIT_LOGOUT_MANUALLY = 'logout',
}
