import { Injectable, inject } from '@angular/core';
import {
    AbstractControl,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from '@angular/forms';
import { format, parse } from 'date-fns';
import {
    ConfirmEventType,
    ConfirmationService,
    MessageService,
    PrimeIcons,
} from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private messageService = inject(MessageService);
    private confirmationService = inject(ConfirmationService);

    constructor() {}

    showSuccessMessage(key: string, detail: string) {
        this.messageService.clear();
        this.messageService.add({
            key,
            detail,
            severity: 'success',
            summary: 'Success',
        });
    }

    showWarningMessage(key: string, detail: string) {
        this.messageService.clear();
        this.messageService.add({
            key,
            detail,
            severity: 'warn',
            summary: 'Warning',
        });
    }

    showErrorMessage(key: string, detail: string) {
        this.messageService.clear();
        this.messageService.add({
            key,
            detail,
            severity: 'error',
            summary: 'Error',
        });
    }

    clearAllMessage() {
        this.messageService.clear();
    }
    clearMessageByKey(key: string) {
        this.messageService.clear(key);
    }

    showToast(dataToast: {
        detail: string;
        severity: 'success' | 'info' | 'warn' | 'error';
        key?: 'global-toast' | 'global-toast-bc' | 'global-toast-tr';
        spinner?: boolean;
        sticky?: boolean;
        summary?: string;
    }) {
        this.messageService.add({
            key: dataToast.key ?? 'global-toast',
            severity: dataToast.severity,
            summary: 'Information',
            detail: dataToast.detail,
            icon: dataToast.spinner
                ? PrimeIcons.SPINNER + ' pi-spin'
                : PrimeIcons.INFO_CIRCLE,
            sticky: dataToast.sticky,
        });
    }

    dismissToast(
        key:
            | 'global-toast'
            | 'global-toast-bc'
            | 'global-toast-tr' = 'global-toast'
    ) {
        this.messageService.clear(key);
    }

    clearMaskPhoneNumberToOnlyNumber(maskedPhoneNumber: string) {
        return maskedPhoneNumber.replace(/[^0-9]/g, '');
    }

    confirmPopup(
        event: Event,
        message: string,
        acceptButtonStyleClass = 'p-button-danger p-button-sm',
        icon: PrimeIcons = PrimeIcons.INFO_CIRCLE
    ) {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });

        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message,
            icon: `${icon}`,
            acceptButtonStyleClass,
            key: 'global-confirm-popup',
            accept: () => {
                resolveFunction(true);
            },
            reject: () => {
                resolveFunction(false);
            },
        });

        return promise;
    }

    showConfirmModal(
        message: string,
        position: 'top' | 'bottom' | 'center' = 'center',
        header: string = 'Confirmation',
        icon: PrimeIcons = PrimeIcons.INFO_CIRCLE,
        typeAction: 'confirmation' | 'delete' = 'delete'
    ) {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });

        this.confirmationService.confirm({
            key: `global-confirm-dialog-${position}`,
            header,
            message,
            acceptButtonStyleClass:
                typeAction === 'delete'
                    ? 'p-button-danger'
                    : 'p-button-primary',
            icon: `${icon}`,
            rejectButtonStyleClass:
                typeAction === 'delete'
                    ? 'p-button-primary'
                    : 'p-button-danger',
            accept: () => {
                resolveFunction(true);
            },
            reject: (type: ConfirmEventType) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        resolveFunction(false);
                        break;
                    case ConfirmEventType.CANCEL:
                        resolveFunction(false);
                        break;
                }
            },
        });

        return promise;
    }

    showModal(dataModal: {
        header?: string;
        message: string;
        position?: 'top' | 'bottom' | 'center' | 'arbitrator';
        icon?: PrimeIcons | undefined;
        acceptLabel?: string;
    }) {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>((resolve) => {
            resolveFunction = resolve;
        });

        this.confirmationService.confirm({
            key: `global-confirm-dialog-${dataModal.position ?? 'center'}`,
            header: dataModal.header ?? 'Information',
            message: dataModal.message,
            icon: `${dataModal.icon ?? undefined}`,
            acceptLabel: dataModal.acceptLabel ?? 'OK',
            rejectVisible: false,
            acceptButtonStyleClass: 'p-button-primary',
            acceptIcon: 'null',
            accept: () => {
                resolveFunction(true);
            },
            reject: (type: ConfirmEventType) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        resolveFunction(false);
                        break;
                    case ConfirmEventType.CANCEL:
                        resolveFunction(false);
                        break;
                }
            },
        });

        return promise;
    }

    get today() {
        return format(new Date(), 'MM/dd/yyyy');
    }

    /**
     *
     * @param parseDate Date
     * @returns dateString 'yyyy-MM-dd'
     */
    dateFormatToDB(parseDate: Date) {
        try {
            return format(parseDate, 'yyyy-MM-dd');
        } catch (error) {
            console.log('🛑 dateFormatToDB ~ error:', error);
            return null;
        }
    }

    /**
     *
     * @param parseStringDate 'MM-dd-yyyy'
     * @returns dateString 'yyyy-MM-dd'
     */
    dateStringFormatToDB(parseStringDate: string) {
        try {
            return format(
                parse(parseStringDate, 'MM-dd-yyyy', new Date()),
                'yyyy-MM-dd'
            );
        } catch (error) {
            console.log('🛑 dateStringFormatToDB ~ error:', error);
            return null;
        }
    }

    /**
     *
     * @param parseStringDateTime 'MM-dd-yyyy HH:mm:ss'
     * @returns dateString 'yyyy-MM-dd'
     */
    dateTimeStringFormatToDB(parseStringDate: string) {
        try {
            return format(
                parse(parseStringDate, 'MM-dd-yyyy HH:mm:ss', new Date()),
                'yyyy-MM-dd HH:mm:ss'
            );
        } catch (error) {
            console.log('🛑 dateStringFormatToDB ~ error:', error);
            return null;
        }
    }

    /**
     *
     * @param parseStringDate 'yyyy-MM-dd'
     * @returns dateString Date
     */
    formatDateToLocal(parseStringDate: Date) {
        try {
            return format(parseStringDate, 'yyyy-MM-dd');
        } catch (error) {
            console.log('🛑 dateFormatDateToLocal ~ error:', error);
            return null;
        }
    }

    /**
     *
     * @param parseStringDate 'yyyy-MM-dd'
     * @returns dateString 'MM/dd/yyyy'
     */
    dateFormatToLocal(parseStringDate: string) {
        try {
            return format(
                parse(parseStringDate, 'yyyy-MM-dd', new Date()),
                'MM/dd/yyyy'
            );
        } catch (error) {
            console.log('🛑 dateFormatToLocal ~ error:', error);
            return null;
        }
    }

    /**
     *
     * @param parseStringTime '05:00 PM'
     * @returns timeString '17:00'
     */
    timeFormatToDB(parseStringTime: string) {
        try {
            return format(parse(parseStringTime, 'p', new Date()), 'HH:mm');
        } catch (error) {
            console.log('🛑 timeFormatToDB ~ error:', error);
            return null;
        }
    }

    markAllAbstractDirty<T extends AbstractControl>(control: T): void {
        if (control instanceof FormGroup) {
            const controls = control.controls;

            Object.keys(controls).forEach((key) => {
                controls[key].markAsDirty();
                this.markAllAbstractDirty(controls[key]);
            });
        } else if (control instanceof FormArray) {
            // console.log('🚀 ~ UtilsService ~ FormArray:', control);
            control.controls.forEach((formControl) => {
                // console.log('🚀 ~ UtilsService ~ formControl:', formControl);
                if (formControl instanceof FormGroup) {
                    this.markAllAbstractDirty(formControl);
                }
                return formControl.markAsDirty();
            });
        } else if (control instanceof FormControl) {
            control.markAsDirty();
        } else {
            throw new Error('Error: unexpected control value');
        }
    }

    enableAddValidatorUpdateFormControl(
        control: FormControl,
        validators: ValidatorFn | ValidatorFn[] = Validators.required
    ): void {
        control.enable();
        control.addValidators(validators);
        control.updateValueAndValidity();
    }

    disableCleanClearUpdateFormControl(control: FormControl): void {
        control.disable();
        control.patchValue('');
        control.clearValidators();
        control.updateValueAndValidity();
    }

    generateRndPProcess() {
        let userPassword = '',
            passwordCharSet = '';
        const plength = 12,
            lowercase = 'abcdefghijklmnopqrstuvwxyz',
            uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            numbers = '0123456789',
            punctuation = '!@#$%^&*()_+~`|}{[]:;?><,./-=';

        passwordCharSet += lowercase;
        passwordCharSet += uppercase;
        passwordCharSet += punctuation;
        passwordCharSet += numbers;

        for (let index = 0x0; index < plength; index++) {
            userPassword += passwordCharSet['charAt'](
                Math['floor'](Math['random']() * passwordCharSet['length'])
            );
        }
        return userPassword;
    }

    getMonthStartDate(month: number, year: number) {
        // Iniciar en este año, este mes, en el día 1
        return new Date(year, month, 1).toISOString().slice(0, 10);
    }

    getMonthEndDate(month: number, year: number) {
        // Iniciar en este año, el siguiente mes, en el día 0 (así que así nos regresamos un día)
        return new Date(year, month + 1, 0).toISOString().slice(0, 10);
    }

    getStartAndEndWeek(date: Date) {
        return {
            start: new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() - date.getDay() + 1
            ),
            end: new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() + 5 - date.getDay()
            ),
        };
    }
}
