import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponseTemplate } from '@core/models/api-response-template.model';
import {
    DataFormRequestAccount,
    LoginForm,
    ResetPasswordForm,
} from '@core/models/forms.models';
import { User } from '@core/models/user.model';
import { UtilsService } from '@core/shared/utils/utils.service';
import { environment } from '@environment/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private http = inject(HttpClient);
    private utils = inject(UtilsService);

    accountRequest(dataForm: DataFormRequestAccount) {
        dataForm.phone = this.utils.clearMaskPhoneNumberToOnlyNumber(
            dataForm.phone
        );
        return lastValueFrom(
            this.http.post(`${environment.apiUrl}account/request`, dataForm)
        );
    }

    async login(data: LoginForm): Promise<any> {
        data.email = data.email.toLowerCase();
        return await lastValueFrom(
            this.http.post<ApiResponseTemplate<User>>(
                `${environment.apiUrl}auth/login`,
                data
            )
        );
    }

    async create2FaVerification(user_id: number) {
        console.log(
            '🚀 ~ AuthService ~ create2FaVerification ~ user_id:',
            user_id
        );
        return await lastValueFrom(
            this.http.post<ApiResponseTemplate<string>>(
                `${environment.apiUrl}token/opt`,
                { user_id }
            )
        );
    }

    async send2FaVerification(code: string, user_id: number) {
        return await lastValueFrom(
            this.http.post<ApiResponseTemplate<User>>(
                `${environment.apiUrl}token/opt/${code}`,
                { user_id }
            )
        );
    }

    forgotPassword(email: string) {
        return lastValueFrom(
            this.http.post(`${environment.apiUrl}auth/resetPassword`, { email })
        );
    }

    resetPassword(data: ResetPasswordForm) {
        return lastValueFrom(
            this.http.post(`${environment.apiUrl}auth/sendResetResponse`, data)
        );
    }

    processAsyncLogout() {
        return lastValueFrom(
            this.http.post(`${environment.apiUrl}logout`, null)
        );
    }
}
