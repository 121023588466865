import { inject, Injectable } from '@angular/core';
import { OAuthStateCallback } from '@core/models/oauth-providers.types';
import { CookieService } from 'ngx-cookie-service';
import { User, UserData } from 'src/app/_core/models/user.model';

const ACCESS_REMEMBER_KEY = '__user_drai_remember';
const ACCESS_USER_KEY = '__user_drai';
const STORAGE_STATUS = '__status_drai';
const STORAGE_STATUS_OAUTH = '__latest_status_oauth';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private cookieService = inject(CookieService);

    getLocal(accessKey: 'user' | 'remember-me' | 'status') {
        switch (accessKey) {
            case 'user':
                return JSON.parse(
                    localStorage.getItem(ACCESS_USER_KEY) ?? 'null'
                );
            case 'remember-me':
                return JSON.parse(
                    localStorage.getItem(ACCESS_REMEMBER_KEY) ?? 'null'
                );
            case 'status':
                return JSON.parse(
                    localStorage.getItem(STORAGE_STATUS) ?? 'null'
                );
        }
    }

    setLocal(accessKey: 'user' | 'remember-me' | 'status', value: any) {
        switch (accessKey) {
            case 'user':
                localStorage.setItem(ACCESS_USER_KEY, JSON.stringify(value));
                break;
            case 'remember-me':
                localStorage.setItem(
                    ACCESS_REMEMBER_KEY,
                    JSON.stringify(value)
                );
                break;

            case 'status':
                localStorage.setItem(STORAGE_STATUS, JSON.stringify(value));
                break;
        }
    }

    setCookieLocal(
        accessKey: 'latest-status-oauth',
        newState: OAuthStateCallback
    ) {
        switch (accessKey) {
            case 'latest-status-oauth':
                this.cookieService.set(
                    STORAGE_STATUS_OAUTH,
                    JSON.stringify(newState),
                    {
                        expires: 1,
                        sameSite: 'Lax',
                    }
                );
                break;
        }
    }

    clearCookieLocal(accessKey: 'latest-status-oauth') {
        switch (accessKey) {
            case 'latest-status-oauth':
                this.cookieService.delete(STORAGE_STATUS_OAUTH);
                break;
        }
    }

    getCookieLocal(accessKey: 'latest-status-oauth') {
        switch (accessKey) {
            case 'latest-status-oauth':
                if (this.cookieService.check(STORAGE_STATUS_OAUTH))
                    return JSON.parse(
                        this.cookieService.get(STORAGE_STATUS_OAUTH)
                    ) as OAuthStateCallback;
                return null;
            default:
                return null;
        }
    }
    getSession(accessKey: 'user') {
        switch (accessKey) {
            case 'user':
                return JSON.parse(
                    sessionStorage.getItem(ACCESS_USER_KEY) ?? 'null'
                );
        }
    }

    setSession(accessKey: 'user', value: any) {
        switch (accessKey) {
            case 'user':
                sessionStorage.setItem(ACCESS_USER_KEY, JSON.stringify(value));
                break;
        }
    }

    validateAndSaveUserOnStorage(valueRemember: boolean, valueUser: User) {
        this.setLocal('remember-me', valueRemember);

        if (valueRemember) {
            this.setLocal('user', valueUser);
        } else {
            this.setSession('user', valueUser);
        }
    }

    validateAndSaveOnlyUserOnStorage(newValueUser: UserData) {
        const valueRemember = this.getLocal('remember-me') as boolean;

        let userOnStorage: User;

        if (valueRemember) {
            userOnStorage = this.getLocal('user') as User;

            userOnStorage.user = newValueUser;

            this.setLocal('user', userOnStorage);
        } else {
            userOnStorage = this.getSession('user') as User;
            userOnStorage.user = newValueUser;
            this.setSession('user', userOnStorage);
        }
    }

    validateAndSaveUserWithAssociatedOnStorage(newValueUser: User) {
        const valueRemember = this.getLocal('remember-me') as boolean;

        if (valueRemember) {
            this.setLocal('user', newValueUser);
        } else {
            this.setSession('user', newValueUser);
        }
    }

    clearUser() {
        localStorage.removeItem(ACCESS_USER_KEY);
        sessionStorage.removeItem(ACCESS_USER_KEY);
    }
}
