import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { Roles } from '@core/_enums/roles.enum';
import { UserStoreService } from '@core/services/store/user-store.service';

export const mainRedirectViewGuard: CanActivateFn = (route, state) => {
    console.log('🚀 ~ mainRedirectViewGuard ~ 🚀');

    const userOnSession = inject(UserStoreService).userSession;
    const router = inject(Router);
    console.log('🚀 ~ userOnSession.roles:', userOnSession?.roles);

    if (userOnSession) {
        //found some role access for the actual Route
        // console.log('#found-some Access Role');

        switch (userOnSession.roles.at(0)) {
            case Roles.SuperAdmin:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            case Roles.SalesRepresentative:
                router.navigate(['/sales-representative/my-clients'], {
                    replaceUrl: true,
                });
                break;

            case Roles.Respondent:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            case Roles.Claimant:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            case Roles.Admin:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            case Roles.Arbitrator:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            case Roles.LegalRepresentative:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            case Roles.BranchManager:
                router.navigate(['/my-disputes'], { replaceUrl: true });
                break;

            default:
                break;
        }

        return true;
    } else {
        // console.log('notFound Access Role');

        router.navigate(['/access-denied'], { replaceUrl: true });
        return false;
    }
};
