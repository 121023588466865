import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { UserStoreService } from '@core/services/store/user-store.service';

export const userNonSessionGuard: CanActivateFn = (route, state) => {
    console.log('🚀 ~ userNonSessionGuard ~ 🚀');

    const userStoreService = inject(UserStoreService);
    const router = inject(Router);

    const currentUser = userStoreService.userSession;
    console.log('🚀 ~ userNonSessionGuard - currentUser => ', currentUser);

    if (!currentUser) {
        return true;
    }

    router.navigate(['/my-disputes'], {
        replaceUrl: true,
    });
    return false;
};
