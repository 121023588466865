import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { withCache } from '@ngneat/cashew';
import { City, ResponseStates } from '@core/models/response-settings.model';
import { environment } from '@environment/environment';
import { ApiResponseTemplate } from '@core/models/api-response-template.model';
import { ConfigurationData } from '@core/models/configurations.model';
import { UserAssociatedToRepresentative } from '@core/models/user.model';
import { UserStoreService } from '@core/services/store/user-store.service';
import { TimeZone } from '@core/models/timezone.model';

@Injectable({
    providedIn: 'root',
})
export class GeneralSettingsService {
    private http = inject(HttpClient);
    private storageService = inject(HttpClient);
    private userStoreService = inject(UserStoreService);

    getStates() {
        return lastValueFrom(
            this.http.get<ResponseStates>(
                `${environment.apiUrl}state?order=asc`,
                {
                    context: withCache({
                        ttl: 604800000, // override to One Week TTl
                        version: '1',
                    }),
                }
            )
        );
    }

    getCitiesByStateId(state_id: number) {
        return lastValueFrom(
            this.http.get<ApiResponseTemplate<City[]>>(
                `${environment.apiUrl}city?order=asc&state_id=${state_id}`,
                {
                    context: withCache({
                        ttl: 86400000, // override to One Day TTl
                        version: '1',
                    }),
                }
            )
        );
    }
    getTermsAndConditionsLink() {
        return lastValueFrom(
            this.http.get<ApiResponseTemplate<ConfigurationData>>(
                `${environment.apiUrl}term-condition`,
                {
                    context: withCache({
                        version: '2',
                    }),
                }
            )
        );
    }

    async getListStatus() {
        await lastValueFrom(
            this.http.get<ApiResponseTemplate<ConfigurationData>>(
                `${environment.apiUrl}status`,
                {
                    context: withCache({
                        ttl: 604800000, // override to One Day TTl
                        version: '1',
                    }),
                }
            )
        ).then(
            (response) => {
                console.log(
                    '🚀 ~ GeneralSettingsService ~ getListStatus ~ response:',
                    response
                );
                // TODO: Save to Storage
            },
            (error) => {
                console.log(
                    '🚀 ~ GeneralSettingsService ~ getListStatus ~ error:',
                    error
                );
            }
        );
    }
    async getUserAssociatedToLegalRepresentative() {
        await lastValueFrom(
            this.http.get<ApiResponseTemplate<UserAssociatedToRepresentative>>(
                `${environment.apiUrl}get-users/representative`
            )
        ).then(
            (response) => {
                console.log(
                    '🚀 ~ GeneralSettingsService ~ getUserAssociatedToLegalRepresentative ~ response:',
                    response
                );

                this.userStoreService.updateOnlyUserAssociatedTo(response.data);
            },
            (error) => {
                console.log(
                    '🚀 ~ GeneralSettingsService ~ getUserAssociatedToLegalRepresentative ~ error:',
                    error
                );
            }
        );
    }

    getTimeZoneList() {
        return lastValueFrom(
            this.http.get<ApiResponseTemplate<TimeZone[]>>(
                `${environment.apiUrl}timezones?utc_order=asc`,
                {
                    context: withCache({
                        ttl: 604800000, // override to One Week TTl
                        version: '1',
                    }),
                }
            )
        );
    }
}
