import { Component, OnInit, inject } from '@angular/core';
import { Roles } from '@core/_enums/roles.enum';
import { SocketPresenceUserService } from '@core/services/data-access/socket-presence-user.service';
import { UserStoreService } from '@core/services/store/user-store.service';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, map, takeUntil } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private readonly onDestroyView$ = new Subject<void>();
    private userStoreService = inject(UserStoreService);
    private socketPresenceUser = inject(SocketPresenceUserService);
    private primengConfig = inject(PrimeNGConfig);

    constructor() {
        console.log('🚀 ~ AppMenuComponent ~ constructor ~ constructor:');
        this.userStoreService.userStream
            .pipe(
                takeUntil(this.onDestroyView$),
                map((userState) => {
                    console.log(
                        '🚀 ~ AppComponent ~ map ~ userState:',
                        userState
                    );

                    if (userState) {
                        // Socket init when user exist, socket ref its empty OR socketRef not connected
                        if (
                            !this.socketPresenceUser.socketRef ||
                            !this.socketPresenceUser.socketRef.connected
                        )
                            this.socketPresenceUser.init(userState.token);
                    }
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
    }

    ngOnDestroy(): void {
        console.log('🚀 ~ AppMenuComponent ~ ngOnDestroy ~ ngOnDestroy:');
        this.onDestroyView$.next();
        this.onDestroyView$.complete();
    }
}
