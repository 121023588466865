<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
    <nav class="layout-breadcrumb" *ngIf="breadcrumbs.length > 0">
        <ol class="card py-3 px-4 border-round">
            <li>
                <a [routerLink]="['/my-disputes']">
                    <i class="pi pi-home"></i>
                </a>
            </li>
            <li class="layout-breadcrumb-chevron">
                <i class="pi pi-chevron-right"></i>
            </li>
            <ng-template
                ngFor
                let-item
                let-last="last"
                [ngForOf]="breadcrumbs$ | async"
            >
                <ng-container *ngIf="!last; else showLastBreadCrumb">
                    <li class="underline">
                        <a [routerLink]="[item.url]">{{ item.label }}</a>
                    </li>
                </ng-container>
                <ng-template #showLastBreadCrumb>
                    <li>
                        <a>{{ item.label }}</a>
                    </li>
                </ng-template>
                <li *ngIf="!last" class="layout-breadcrumb-chevron">
                    <i class="pi pi-chevron-right"></i>
                </li>
            </ng-template>
        </ol>
    </nav>
</ng-container>
