import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { userAuthGuard } from '@core/_guards/user-auth.guard';
import { Roles } from '@core/_enums/roles.enum';
import { rolesGuard } from '@core/_guards/roles.guard';
import { userNonSessionGuard } from '@core/_guards/user-non-session.guard';
import { mainRedirectViewGuard } from '@core/_guards/main-redirect-view.guard';

const routes: Routes = [
    {
        path: '',
        children: [],
        canActivate: [userAuthGuard, mainRedirectViewGuard],
    },
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: 'my-disputes',
                data: {
                    breadcrumb: 'My Disputes',
                    roles: [
                        Roles.SuperAdmin,
                        Roles.Claimant,
                        Roles.Respondent,
                        Roles.Arbitrator,
                        Roles.LegalRepresentative,
                        Roles.BranchManager,
                    ],
                },
                loadChildren: () =>
                    import(
                        './my-cases/feature/my-cases-shell/my-cases-shell.routes'
                    ),
                canActivate: [userAuthGuard, rolesGuard],
            },
            {
                path: 'settings',
                data: {
                    roles: [
                        Roles.SuperAdmin,
                        Roles.Claimant,
                        Roles.Respondent,
                        Roles.SalesRepresentative,
                        Roles.Arbitrator,
                        Roles.LegalRepresentative,
                        Roles.BranchManager,
                    ],
                },
                loadChildren: () =>
                    import(
                        './settings/feature/settings-shell/settings-shell.routes'
                    ),
                canActivate: [userAuthGuard, rolesGuard],
            },
            {
                path: 'branches',
                data: {
                    breadcrumb: 'Branch Case Load',
                    roles: [Roles.Claimant],
                },
                loadChildren: () =>
                    import(
                        './branches/feature/branches-shell/branches-shell.routes'
                    ),
                canActivate: [userAuthGuard, rolesGuard],
            },
            {
                path: 'negotiation-chat-room',
                data: {
                    breadcrumb: 'Settlement Agreement Page',
                    roles: [
                        Roles.Claimant,
                        Roles.Respondent,
                        Roles.Arbitrator,
                        Roles.LegalRepresentative,
                        Roles.BranchManager,
                    ],
                },
                loadChildren: () =>
                    import(
                        './negotiation-chat-room/feature/negotiation-chat-room-shell/negotiation-chat-room-shell.routes'
                    ),
                canActivate: [userAuthGuard, rolesGuard],
            },
            {
                path: 'sales-representative',
                data: {
                    roles: [Roles.SalesRepresentative],
                },
                loadChildren: () =>
                    import(
                        './sales-representative/feature/sales-representative-shell/sales-representative-shell.routes'
                    ),
                canActivate: [userAuthGuard, rolesGuard],
            },
            {
                path: 'calendar',
                data: {
                    breadcrumb: 'Calendar',
                    roles: [
                        Roles.Claimant,
                        Roles.Arbitrator,
                        Roles.LegalRepresentative,
                        Roles.BranchManager,
                    ],
                },
                loadChildren: () =>
                    import(
                        './calendar/feature/calendar-shell/calendar-shell.routes'
                    ),
                canActivate: [userAuthGuard, rolesGuard],
            },
        ],
    },
    {
        path: 'auth',
        data: { breadcrumb: 'Auth' },
        loadChildren: () =>
            import('./auth/feature/auth-shell/auth-shell.routes'),
        canActivate: [userNonSessionGuard],
    },
    {
        path: 'oauth-callback-close',
        loadComponent: () =>
            import('@module-auth/ui/oauth-close/oauth-close.component').then(
                (c) => c.OauthCloseComponent
            ),
    },
    {
        path: 'registration-form',
        data: {},
        loadChildren: () =>
            import(
                './registration-form/feature/registration-form-shell/registration-form-shell.routes'
            ),
    },
    {
        path: 'not-found',
        loadComponent: () =>
            import('./_core/shared/ui/not-found/not-found.component').then(
                (c) => c.NotfoundComponent
            ),
    },
    {
        path: 'access-denied',
        loadComponent: () =>
            import(
                './_core/shared/ui/access-denied/access-denied.component'
            ).then((c) => c.AccessdeniedComponent),
    },
    { path: '**', redirectTo: '/not-found' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
